import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"https://connect.facebook.net/de_DE/sdk.js#xfbml=1&appId=272376829538322&version=v2.0\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));"
          }}
        />
        <Header></Header>
        <div id="meganav">
          <div className="container">
            {" "}
            <a
              href="/"
              title="bebe Zartpflege®"
              target="_self"
              className="navbar-brand"
            >
              <em>
                bebe Zartpflege<sup>®</sup>
              </em>
            </a>
            <div className="close-meganav" />
            <div className="col-xs-12 meganav-top">
              {" "}
              <a
                href="/"
                target="_self"
                title="Start"
                className="navitem"
              >
                Start
              </a>{" "}
              <a
                href="/menu/kontakt/"
                target="_self"
                title="Kontakt"
                className="navitem"
              >
                Kontakt
              </a>{" "}
              <a
                href="/menu/faq/"
                target="_self"
                title="FAQ"
                className="navitem"
              >
                FAQ
              </a>{" "}
              <a
                href="/menu/impressum/"
                target="_self"
                title="Impressum"
                className="navitem"
              >
                Impressum
              </a>{" "}
              <a
                href="/menu/rechtliche-hinweise/"
                target="_self"
                title="Rechtliche Hinweise"
                className="navitem"
              >
                Rechtliche Hinweise
              </a>{" "}
              <a
                href="/menu/cookie-information/"
                target="_self"
                title="Cookies"
                className="navitem"
              >
                Cookies
              </a>{" "}
              <a
                href="/menu/allg-gewinnspielbedingungen/"
                target="_self"
                title="Allg. Gewinnspielbedingungen"
                className="navitem"
              >
                Allg. Gewinnspielbedingungen
              </a>{" "}
            </div>
            <div id="col-products" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/produkte/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.produkte.png"
                  alt="Produkte"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/produkte/"
                    target="_self"
                    title="Produkte"
                  >
                    Produkte
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/produkte/#ZartcremeinderDose"
                        target="_self"
                      >
                        Zartcreme
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#schaumbad-und-dusche"
                        target="_self"
                      >
                        Schaumbad und Dusche
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Glanzshampoo"
                        target="_self"
                      >
                        Glanzshampoo
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#Pflegelotion"
                        target="_self"
                      >
                        Pflegelotion
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#MildeHandseife"
                        target="_self"
                      >
                        Milde Handseife
                      </a>
                    </li>
                    <li>
                      <a
                        href="/produkte/#shampoo"
                        target="_self"
                      >
                        Stärkendes Shampoo und Spülung
                      </a>
                    </li>
                    <li>
                    <a
                      href="/produkte/#Lippenpflegemit"
                      target="_self"
                    >  
                     Lippenpflege mit fruchtigem Kirschduft
                    </a>
                </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-unsere-maedels"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/unsere-maedels/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.unsere-maedels.png"
                  alt="Unsere Mädels"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/unsere-maedels/"
                    target="_self"
                    title="Unsere Mädels"
                  >
                    Unsere Mädels
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/unsere-maedels/#BibiBlubberblase"
                        target="_self"
                      >
                        Bibi Blubberblase
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#MiaMorgentau"
                        target="_self"
                      >
                        Mia Morgentau
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#PaulaPerlensanft"
                        target="_self"
                      >
                        Paula Perlensanft
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#ViviWallemaehne"
                        target="_self"
                      >
                        Vivi Wallemähne
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#SophieSonnenschein"
                        target="_self"
                      >
                        Sophie Sonnenschein
                      </a>
                    </li>
                    <li>
                      <a
                        href="/unsere-maedels/#Malbuch"
                        target="_self"
                      >
                        Mal- &amp; Rätselbuch
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div id="col-marken" className="cat-links col-xs-12 col-sm-3">
              {" "}
              <a
                className="cat-thumb"
                href="/markenhistorie/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.markenhistorie.png"
                  alt="Markenhistorie"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/markenhistorie/"
                    target="_self"
                    title="Markenhistorie"
                  >
                    Markenhistorie
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a href="/markenhistorie/#60er" target="_self">
                        60er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#70er" target="_self">
                        70er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#80er" target="_self">
                        80er
                      </a>
                    </li>
                    <li>
                      <a href="/markenhistorie/#90er" target="_self">
                        90er
                      </a>
                    </li>
                    <li>
                      <a
                        href="/markenhistorie/#2000er"
                        target="_self"
                      >
                        2000er
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div
              id="col-limited-editions"
              className="cat-links col-xs-12 col-sm-3"
            >
              {" "}
              <a
                className="cat-thumb"
                href="/limited-editions/"
                target="_self"
              >
                {" "}
                <img
                  src="/fileadmin/images/_layout/meganav.thumb.limited_editions.png"
                  alt="Limited Editions"
                />{" "}
              </a>
              <ul>
                <li>
                  <a
                    href="/limited-editions/"
                    target="_self"
                    title="Limited Editions"
                  >
                    Limited Editions
                  </a>
                </li>
                <li>
                  <ul className="sub-navi">
                    <li>
                      <a
                        href="/limited-editions/#OnlineGame"
                        target="_self"
                      >
                        Online Game
                      </a>
                    </li>
                    <li>
                      <a
                        href="/limited-editions/#LimitedEditions"
                        target="_self"
                      >
                        Limited Editions
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 meganav-mobile-bottom">
              {" "}
              <a
                className="navitem"
                title="Start"
                target="_self"
                href="/"
              >
                Start
              </a>{" "}
              <a
                className="navitem"
                title="Kontakt"
                target="_self"
                href="/menu/kontakt/"
              >
                Kontakt
              </a>{" "}
              Newsletter{" "}
            </div>
          </div>
        </div>
        <div id="content">
          <div className="container">
            <div id className="row stageless mb-50">
              <div className="text-center col-sm-8 col-sm-offset-2">
                <h1>Rechtlicher Hinweis</h1>
              </div>
            </div>
            <div id className="row">
              <div className=" col-sm-8 col-sm-offset-2">
                <div className="accordion">
                  <h3>Anwendungsbereich</h3>
                  <div>
                    <p>
                      Diese Website gehört der Johnson &amp; Johnson GmbH (im
                      Folgenden Betreiber). Sie richtet sich an Besucher in
                      Deutschland und Österreich. Ihre Benutzung unterliegt den
                      folgenden Nutzungsbedingungen. Wenn Sie Services dieser
                      Websites in Anspruch nehmen, gelten daher diese
                      Nutzungsbedingungen.
                      <br /> <br /> Beachten Sie bitte, dass wir unsere
                      Nutzungsbedingungen unseren Services, Anforderungen und
                      mit Rücksicht auf Gesetzesänderungen von Zeit zu Zeit
                      anpassen. Bitte schauen Sie immer wieder auf diese Seite,
                      um von solchen Änderungen zu erfahren. Ein ausdrücklicher
                      Hinweis auf die Änderung der Nutzungsbedingungen erfolgt
                      nicht. Daher sollten Sie sich bei jeder Nutzung von den
                      jeweils aktuellen Nutzungsbedingungen überzeugen. Der
                      Stand ist datumsmäßig am Ende dieser Nutzungsbedingungen
                      benannt.
                    </p>
                  </div>
                  <h3>Aktualität</h3>
                  <div>
                    <p>
                      Der Betreiber dieser Website wird in angemessener Weise
                      Anstrengungen unternehmen, damit diese Website aktuelle
                      und genaue Informationen enthält. Er gibt aber keine
                      Zusicherungen, Gewährleistungen oder Zusagen hinsichtlich
                      der Richtigkeit, Gültigkeit oder Vollständigkeit der
                      bereitgestellten – unverbindlichen – Informationen. Der
                      Betreiber haftet daher nicht für Schäden, die sich daraus
                      ergeben, dass Sie sich auf Informationen auf dieser
                      Website verlassen haben.
                    </p>
                  </div>
                  <h3>Unsere Verantwortung</h3>
                  <div>
                    <ul>
                      <li>
                        <span
                          style={{
                            "line-height": "1.42857143"
                          }}
                        >
                          Diese Website kann auch Links oder Verweise auf andere
                          Websites enthalten, aber die Betreiber dieser Website
                          übernehmen keine Verantwortung für den Inhalt solcher
                          Websites und keine Haftung für Schäden oder
                          Verletzungen, die aus der Nutzung – gleich welcher Art
                          – solcher Inhalte entstehen. Links zu anderen Websites
                          werden den Nutzern dieser Website lediglich als
                          Annehmlichkeit zur Verfügung gestellt.
                        </span>
                      </li>
                      <li>
                        Der Betreiber ist berechtigt, alle in Kommunikation ihm
                        gegenüber offengelegten Informationen, einschließlich
                        aller darin enthaltenen Ideen, Erfindungen, Konzepte,
                        Methoden oder enthaltenes Know-how, für jeden Zweck zu
                        verwenden, beispielsweise für die Bekanntgabe an Dritte
                        und/oder die Entwicklung, Herstellung und/oder
                        Vermarktung von Produkten oder Dienstleistungen, sofern
                        nicht etwas anderes – wie eine Vertraulichkeit –
                        vereinbart worden oder gesetzlich vorgeschrieben ist.
                      </li>
                      <li>
                        Der Betreiber haftet unbeschränkt für Vorsatz und grobe
                        Fahrlässigkeit dem Grunde nach. Bei leichter
                        Fahrlässigkeit kommt eine Haftung nur in Betracht, wenn
                        wesentliche, vertragliche Pflichten (Kardinalpflichten)
                        verletzt werden. Im Übrigen ist die Haftung – soweit
                        zulässig – auf den vertragstypischen Schaden
                        betragsmäßig begrenzt. Eine Haftung für Folgeschäden,
                        namentlich für entgangenen Gewinn oder immaterielle
                        Schäden, ist – soweit zulässig – ausgeschlossen. Für
                        Ihre Datenverluste oder sonstige Schäden an Ihrer Hard-
                        oder Software ist der Betreiber nicht verantwortlich.
                        Der Betreiber haftet allerdings unbeschränkt für Schäden
                        aus der Verletzung des Lebens, des Körpers oder der
                        Gesundheit. Die vorgenannten Haftungsbeschränkungen
                        gelten zugunsten der Beschäftigten, der gesetzlichen
                        Vertreter und der Erfüllungsgehilfen des Betreibers und
                        seiner Dienstleister entsprechend.
                      </li>
                      <li>
                        Der Betreiber tritt nicht für Schäden ein, die sich aus
                        dem Zugriff oder fehlenden Zugriff auf diese Website
                        ergeben.
                      </li>
                      <li>Ihre Verantwortung Verbindliche Netiquette</li>
                      <li>
                        Sofern Sie die Services des Betreibers in Anspruch
                        nehmen, sind wahrheitsgemäße und vollständige Angaben zu
                        Ihrer Person – insbesondere Vorname, Name, Adresse,
                        Telefonnummer sowie E-Mail-Adresse – unabdingbare
                        Voraussetzung. Diese Website differenziert, welche
                        einzelnen Angaben von Ihnen für die jeweiligen Services
                        verbindlich oder unverbindlich sind. Bitte aktualisieren
                        Sie Ihre persönlichen Angaben, wenn sie sich ändern.
                      </li>
                      <li>
                        Der Absender jeglicher Kommunikation an diese Website
                        oder an den Betreiber ist für den Inhalt sowie die darin
                        enthaltenen Informationen, einschließlich deren
                        Richtigkeit und Genauigkeit, verantwortlich.
                      </li>
                      <li>
                        Bitte geben Sie Ihre Zugangsdaten nicht weiter. Ein
                        Nutzerkonto ist ausschließlich für Ihren persönlichen
                        Gebrauch vorgesehen. Halten Sie Ihren Benutzernamen und
                        Ihr Passwort vor anderen geheim. Wenn Sie den Eindruck
                        haben, Dritte würden unter Ihrem Benutzernamen handeln,
                        informieren Sie uns bitte unverzüglich; schriftlich oder
                        gern per E-Mail an{" "}
                        <a className="mail">
                          its.jnj.datenschutz(at)kramerdatenschutz.de
                        </a>
                        .
                      </li>
                      <li>
                        Es ist untersagt, Material auf die Website zu
                        übertragen, das schadenstiftende Inhalte (insbesondere
                        Malicious Code) und sonstige Programme enthält, welche
                        die Funktionsfähigkeit von Hard- und/oder Software
                        gefährden oder stören können. Sonstige Handlungen, die
                        die System- oder Netzwerksicherheit verletzen oder dies
                        beabsichtigen, wie beispielsweise das Verschaffen eines
                        unautorisierten Zugangs durch das Einschleusen eines
                        Malicious Code oder die Versendung von Spam, sind nicht
                        erlaubt.
                      </li>
                      <li>
                        Der Nutzer hat für die Nachteile einzustehen, die durch
                        seine missbräuchliche oder rechtswidrige Verwendung der
                        Website entstehen.{" "}
                      </li>
                    </ul>
                  </div>
                  <h3>Kinder/Jugendliche</h3>
                  <div>
                    <p>
                      Wenn Sie noch nicht volljährig sind, nutzen Sie diese
                      Website nur mit Zustimmung Ihrer Erziehungsberechtigten.
                      Eine Registrierung oder Eingaben und Abrufe kommt also nur
                      in Betracht, wenn Sie mindestens das 18 Jahre Lebensjahr
                      vollendet haben und voll geschäftsfähig sind.
                    </p>
                  </div>
                  <h3>Ihre Nutzungsrechte / Unsere Urheber- / Markenrechte</h3>
                  <div>
                    <ul>
                      <li>
                        Die Marken, Markennamen und Firmen dieser Website wie
                        ihre Bilder, Texte und/oder grafischen Gestaltungen sind
                        in Deutschland sowie international marken-, namenschutz-
                        und/oder urheberrechtlich geschützt. Sie dürfen in
                        keiner Weise ohne die vorherige schriftliche Zustimmung
                        des Betreiber dieser Website genutzt oder sonst
                        verwendet werden außer zur Identifizierung der Produkte
                        oder Serviceleistungen des Unternehmens und im Übrigen
                        ausschließlich für den privaten, nicht gewerblichen
                        Gebrauch.
                      </li>
                      <li>
                        Markenbezeichnungen und Urheberrechtshinweise dürfen in
                        keinem Fall verändert oder beseitigt werden. Die
                        kommerzielle Nutzung, insbesondere das Einstellen
                        kommerzieller Werbung, ist untersagt.
                      </li>
                      <li>
                        Im Übrigen wird durch die Benutzung der Website weder
                        ausdrücklich, konkludent noch auf sonstige Weise eine
                        Lizenz oder ein Recht zum Gebrauch geistiger
                        Eigentumsrechte gewährt, deren Gegenstand auf dieser
                        Website enthalten ist. Jegliche widerrechtliche
                        Verwendung ist ausdrücklich untersagt.
                      </li>
                      <li>
                        Aus den Nutzungsbedingungen folgende Rechte und
                        Pflichten sind auch nicht teilweise ohne die vorherige
                        Zustimmung des Betreibers durch Sie auf Dritte
                        übertragbar.
                      </li>
                      <li>
                        Der Betreiber steht nicht dafür ein, dass das auf der
                        Website angezeigte Material nicht in Rechte Dritter
                        eingreift.
                      </li>
                    </ul>
                  </div>
                  <h3>Datenschutzverweis</h3>
                  <div>
                    <p>
                      Alle personenbezogenen Daten, die in elektronischer
                      Kommunikation über diese Website übermittelt werden,
                      unterliegen dem Datenschutz.{" "}
                      <a href="/datenschutzbedingungen/">Hier</a>{" "}
                      finden Sie ausführliche Informationen über die Verwendung
                      und Speicherung Ihrer Daten durch diese Website.
                    </p>
                  </div>
                  <h3>Beendigung</h3>
                  <div>
                    <p>
                      Der Betreiber behält sich das Recht vor, den Inhalt dieser
                      Website jederzeit aus freiem Grund ohne Ankündigung zu
                      streichen, zu ändern oder zu ergänzen, sofern nicht
                      ausdrücklich Services auf anderer Grundlage erbracht
                      werden. Ein Rechtsanspruch besteht nur dann, wenn dieser
                      ausdrücklich begründet oder kraft Gesetzes gegeben ist.
                    </p>
                  </div>
                  <h3>Anwendbares Recht</h3>
                  <div>
                    <p>
                      Diese Nutzungsbedingungen unterliegen – soweit zulässig –
                      dem deutschen Recht.
                    </p>
                  </div>
                  <h3>Unwirksamkeit</h3>
                  <div>
                    <p>
                      Sollten einzelne Bestimmungen dieser Nutzungsbedingungen
                      unwirksam sein oder werden oder den gesetzlichen
                      Regelungen widersprechen, so wird hierdurch die
                      Wirksamkeit der übrigen Nutzungsbedingungen nicht berührt.
                      Die unwirksame Bestimmung wird von den Parteien
                      einvernehmlich durch eine solche Bestimmung ersetzt,
                      welche dem wirtschaftlichen Zweck der unwirksamen
                      Bestimmung in rechtswirksamer Weise am nächsten kommt. Die
                      vorstehende Regelung gilt entsprechend bei
                      Regelungslücken.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          src="/fileadmin/js/vendor/jquery6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/detectmobilebrowser6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/bootstrap.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.ba-throttle-debounce.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/skrollr.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/isMobile.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/vendor/jquery.scrollTo.min6b21.js?1400849731"
          type="text/javascript"
        />
        <script
          src="/fileadmin/js/script71c5.js?1527068951"
          type="text/javascript"
        />
      </div>
    );
  }
}

export default Page;
